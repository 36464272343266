import * as React from 'react';
import ImageGallery from 'react-image-gallery';
import './index.css';
export default class MyGallery extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      images: [],
    };
  }
  render() {
    return <ImageGallery 
    items={
        this.props.images.map((image, key) => { return {
          original:`${image[this.props.index]}`, 
          thumbnail:`${image[this.props.index]}`, 
          description :image.titulo,
          thumbnailLabel: image.visita ?  `Visita # ${image.visita}` :  `Evidencia # ${key + 1}`
        }
      }) 
    } 
    showPlayButton={false}
    />;
  }
}

MyGallery.defaultProps = {
  index: "url",
  images:[]
}