import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';


const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    title: {
      flex: '1 1 100%',
    },
  }));

const EnhancedTableToolbar = ({title}) => {
    const classes = useToolbarStyles();
    // let history = useHistory();
    return (
      <Toolbar
        className={clsx(classes.root)}
      >
          <Typography className={classes.title} variant="h5" id="tableTitle" component="div">
            {title}
          </Typography>
      </Toolbar>
    );
  };

  
export default function BasicTable({
    catalog_id,
    title,
    data,
    columns,
    open_answers,
    loading,
    ...props}) {
  return (
    <TableContainer component={Paper} sx ={{padding: '16px', marginBottom:"30px"}}>
        <EnhancedTableToolbar
            title={title}
        />
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
            <TableRow>
            {columns.map((headCell) => (
            <TableCell
                key={headCell.id}
                align={headCell.numeric ? 'right' : 'left'}
                padding={'normal'}
            >{ headCell.label}</TableCell>
            ))}
            </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, row_key) => (
            row.tipo ==="Abierta" 
            ?
                <>
                <TableRow
                    hover
                    key={row_key}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    {columns.map((column, index) => {
                        return (
                            <TableCell key={index} align={column.numeric ? 'right' : 'left'} >
                                {row[column.id] }
                            </TableCell> 

                        )
                    })}
                </TableRow>
                {(open_answers[row.id]) && 
                    open_answers[row.id].respuestas.map((respuesta, j) => {
                    return (
                            <TableRow key={`${row.id}_${j}`} align={'right'} >
                                <TableCell colSpan={4} align={'left'} >
                                    <div dangerouslySetInnerHTML={{__html: respuesta}}/>
                                </TableCell> 
                            </TableRow> 
                        )
                    })
                  }
                </>
            :

            <TableRow
                hover
                key={row_key}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                {columns.map((column, index) => {
                    return (
                        <TableCell key={index} align={column.numeric ? 'right' : 'left'} >
                            {row[column.id] }
                        </TableCell> 

                    )
                })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}