import * as React from 'react';
import DataTable from '../table';
import {Grid, Paper} from '@mui/material';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function DataGridDemo({global_results = [], catalog_results = [],catalogs=[], business_branches={}, multiple_results=[], loading = false, ...props}) {

  const columns_gobal_results = [
    {
      id: 'establecimiento',
      label: 'Establecimiento',
      sortable: true,
      numeric: false,
      disablePadding: true
    },
    ...catalogs.map( (element,index) =>{ return {
      id: `categoria${element.id}`,
      label: element.nombre,
      sortable: true,
      numeric: true,
      disablePadding: true
      }}
    ),
    {
      id: 'calificacion',
      label: 'Calificación Global',
      sortable: true,
      numeric: true,
      disablePadding: true
    }
  ];

  const columns_calatogs_results = [
    {
      id: 'titulo',
      label: 'Criterio',
      sortable: true,
      numeric: false,
      disablePadding: true
    },
    ...Object.keys(business_branches).map((key,i)=>{ return {
      id: `establecimiento${key}`,
      label: business_branches[key],
      sortable: true,
      numeric: true,
      disablePadding: true
      }}
    ),
    {
      id: 'promedio',
      label: 'Promedio',
      sortable: true,
      numeric: true,
      disablePadding: true
    }
  ];


  const options_bar_char = ( {title = ""}) =>(  {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: title,
      },
    }
  });

  return (
    <div style={{ width: '100%' }}>

      <Grid item container spacing={3}>
        
        <Grid item xs={12} id={"global_results"}>
          <Paper elevation={1} sx={{padding: '16px',display: 'flex',overflow: 'auto',flexDirection: 'column'}}>
            <DataTable
              title = {"Resultados Globales"}
              data = {global_results}
              columns={columns_gobal_results}
              loading={loading}
            />
          </Paper>
        </Grid>

        {catalogs.map((catalog, index) => (
          <Grid id={`catalog_${catalog.id}`} item xs={12} key={index}>
            <Paper elevation={1} sx={{padding: '16px',display: 'flex',overflow: 'auto',flexDirection: 'column'}}>
              <DataTable
                title = {catalog.nombre}
                data = {catalog_results[catalog.id].filter(x => x[columns_calatogs_results[1].id] !==null)}
                columns={columns_calatogs_results}
                loading={loading}
              />
            </Paper>
            {/* {multiple_results[catalog.id] && <pre>{JSON.stringify(multiple_results[catalog.id], 0, 2)}</pre>} */}
            {multiple_results[catalog.id] && Object.keys(multiple_results[catalog.id]).map((key,i)=>(
              <Bar key={i} options={options_bar_char({ title: multiple_results[catalog.id][key].criterio})} data={
                  {
                    labels: multiple_results[catalog.id][key].labels,
                    datasets: multiple_results[catalog.id][key].respuestas
                  }
                }
              />
            ))}  
          </Grid>
        ))}
      </Grid>
    </div>
  );
}